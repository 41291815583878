import * as _ from "lodash";

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import minMax from 'dayjs/plugin/minMax'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { User as FirebaseUser } from "firebase/auth";

import {
    EmailGroupType, EmailTemplateOption, EmailType,
    EventType, LeagueType, MatchType, SpotType, AvailabilityType, AvailabilityValueType, hCourtTimeType, hTemplateType, EmailPartialType, PartialDocumentType, PartialType,
} from "./types";

import Handlebars from "handlebars";

///////////////////
//
//   USE EMULATOR ?
//
///////////////////
export const user_emulator = false
export const VERSION = 1.25

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(calendar)
dayjs.extend(minMax)
dayjs.extend(localizedFormat)

Handlebars.registerHelper('ifEquals', (v1, v2, options) => {
    if (v1 === v2) {
        return options.fn(this);
    }
    return options.inverse(this);
});


export type hEmailGroupsType = {
    [key in EmailGroupType as string]: string[]
}

type CourtSpotDataType = {
    side: string;
    player?: EmailPlayerType & { isSingleRecipient: boolean }
    open: boolean;
    wins: boolean;
    loses: boolean;
    draws: boolean;
}

type CourtDataType = {
    name: string;
    spots: CourtSpotDataType[];
    aspots: CourtSpotDataType[];
    bspots: CourtSpotDataType[];
    when: {
        date_short: string;
        date_long: string;
        time: string;
        calendar: string;
    };
    score: string;
}
export type EventDataType = {
    description: string;
    location: string;
    has_address: boolean;
    show_times: boolean; // set to true if one match has an offset
    when: {
        date_short: string;
        date_long: string;
        time: string;
        calendar: string;
    };
    courts: CourtDataType[];
    numberopenspots: number;
}

export type EmailPlayerType = {
    first: string;
    fullname: string;
    availability: string;
    available: boolean;
    playing: boolean;
    confirmed: boolean;
}

export type LeagueDataType = {
    name: string;
    admins: string[];
    eventtype: string;
}

export type EmailDataType = {
    league: LeagueDataType;
    event: EventDataType;
    recipients?: EmailPlayerType[],
    recipient?: EmailPlayerType & { playing_time: string, playing_court: string },
    groups: { [g: string]: string[] },
    groupids: { [g: string]: string[] },
    groups_counts: { [g: string]: number },
    // availabilitygroups: { group: string, isavailableandplaying: boolean, persons: string[] }[],
    options: { [k: string]: boolean | string | undefined },
    replaceables: { [k: string]: string },
    email?: EmailType
}



//
// compute open spots
//

export const matchOpenSpots = (match: MatchType, event: EventType) => {
    return match.spots
        ? Object.values(match.spots)
            .filter(
                (s) =>
                    match.players.filter(
                        (p) =>
                        (p.position === s.position && (p.postitname !== undefined ||
                            event.availabilities[p.PlayerID || ""]?.availability !== "none"))
                    ).length === 0
            )
            .map((spot) => {
                return { ...spot, MatchID: match.MatchID };
            })
        : [];
};


//
// compute first match: court, time, participants
//

type FirstMatchDetailsType = {
    court: string;
    startTime: string;
    participants: string[];
}

export const firstMatch = (event: EventType, league: LeagueType, pid: string) => {
    var res: FirstMatchDetailsType | undefined = undefined;

    const matches = _.pickBy(event.matches, ((m, mid) => m.players.findIndex(p => p.PlayerID === pid) >= 0))
    const firstMatchStartTime = dayjs.min(Object.values(matches).map(m => dayjs(event.when).add(m.offset || 0, "minute")))
    if (firstMatchStartTime) {
        const firstMatch = Object.keys(matches).find((mid) => dayjs(event.when).add(event.matches[mid].offset || 0, "minute").isSame(firstMatchStartTime))
        if (firstMatch) {
            res = {
                court: matches[firstMatch].court,
                startTime: firstMatchStartTime.toISOString(),
                participants: matches[firstMatch].players.filter(p => p.PlayerID !== pid).map(p => p.PlayerID ? (league.playerDetails[p.PlayerID]?.name || "???") : p.postitname || "!!!")
            }
        }
    }

    return res;
}

//
// compute court time by player for an event
//

export const eventCourtTimes = (event: EventType, league?: LeagueType) => {
    const he: hCourtTimeType = {}

    // we go through the matches of the event
    Object.entries(event.matches).forEach(([km, vm]) => {
        // we compute the court time used for this match
        const h = matchCourtTimes(vm, event, league)
        // we accumulate
        Object.entries(h).forEach(([kp, vp]) => {
            he[kp] = he[kp] || { w: 0, l: 0, d: 0, minutes: 0 }
            he[kp].minutes += vp.minutes
            he[kp].w += vp.w
            he[kp].l += vp.l
            he[kp].d += vp.d
        })
    })

    return he
}

export const eventCourtTimesWithOverrides = (event: EventType, league?: LeagueType) => {
    const he = eventCourtTimes(event, league)
    if (event.courtTimeOverrides) {
        Object.entries(event.courtTimeOverrides).forEach(([k, v]) => {
            if (v) {
                if (he[k]) {
                    he[k].minutes = v;
                } else {
                    he[k] = {
                        minutes: v,
                        w: 0,
                        l: 0,
                        d: 0,
                    }
                }
            }
        })
    }
    return he;
}

//
// compute league court time
//

export const leagueCourtTimes = (league: LeagueType, events?: string[]) => {
    const he: hCourtTimeType = {}

    Object.entries(league?.eventsSummary || []).map(([PlayingDayID, es]) => {
        if (!events || events.includes(PlayingDayID))
            Object.entries(es.playerTimeAndResults).forEach(([kp, vp]) => {
                he[kp] = he[kp] || { w: 0, l: 0, d: 0, minutes: 0 }
                he[kp].minutes += vp.minutes
                he[kp].w += vp.w
                he[kp].l += vp.l
                he[kp].d += vp.d
            })
    })
    // events.forEach(e => {
    //     const h = eventCourtTimes(e, league)
    //     // we accumulate
    //     Object.entries(h).forEach(([kp, vp]) => {
    //         he[kp] = he[kp] || { w: 0, l: 0, d: 0, minutes: 0 }
    //         he[kp].minutes += vp.minutes
    //         he[kp].w += vp.w
    //         he[kp].l += vp.l
    //         he[kp].d += vp.d
    //     })
    // })
    return he
}


//
// computes court time by player for a match
//

export const matchCourtTimes = (match: MatchType, event: EventType, league?: LeagueType) => {
    const nbpositions = Object.keys(match.spots).length
    const h: hCourtTimeType = {}

    // for each player present on court
    match.players.forEach((c) => {
        if (c.PlayerID) { // otherwise it's a postit and we don't care about them
            h[c.PlayerID] = h[c.PlayerID] || { w: 0, l: 0, d: 0, minutes: 0 }
            h[c.PlayerID].minutes += (match.minutes || league?.minutes || 90) / nbpositions;

            const ps = match.players.filter(p => p.PlayerID == c.PlayerID)
            if (ps && ps.length === 1) {
                const p = ps[0]
                h[c.PlayerID].w += p.position[0] === "A" && match.awins === "W" ? 1 : 0
                h[c.PlayerID].w += p.position[0] === "B" && match.bwins === "W" ? 1 : 0
                h[c.PlayerID].l += p.position[0] === "A" && match.awins === "L" ? 1 : 0
                h[c.PlayerID].l += p.position[0] === "B" && match.bwins === "L" ? 1 : 0
                h[c.PlayerID].d += p.position[0] === "A" && match.bwins === "D" ? 1 : 0
                h[c.PlayerID].d += p.position[0] === "B" && match.awins === "D" ? 1 : 0
            }
        }
    },)
    return h;
}

//
// compute open spots in an event
//

export const eventOpenSpots = (event: EventType) => {
    const spots = Object.values(event.matches).reduce(
        (p: SpotType[], c) => p.concat(matchOpenSpots(c, event)),
        [] as SpotType[]
    ) as SpotType[];
    return spots;
};


//
// Compute update after availability change
//

// we take the current event, apply the availability change of pid, then compute openspots

export const computePlayerAvailabilityUpdate = (
    event: EventType,
    pid: string,
    availability: string,
    confirmed?: boolean
) => {
    // to compute open spots we duplicate event, apply changes in mem,
    // then apply all changes including open spots to db
    const newevent = _.cloneDeep(event);

    if (!newevent.availabilities[pid])
        newevent.availabilities[pid] = {
            pid: pid,
        } as AvailabilityType;
    newevent.availabilities[pid].availability =
        availability as AvailabilityValueType;
    const openspots = eventOpenSpots(newevent);

    // console.log("Updating availability for " + pid + " to " + availability);
    const updating: { [k: string]: any } = {};
    updating["availabilities." + pid + ".availability"] = availability;
    if (confirmed != undefined)
        updating["availabilities." + pid + ".confirmed"] = confirmed;

    // if new avalability is positive and old one negative, we add timestamp
    if (
        availabilityTable[availability] &&
        (!event.availabilities[pid] ||
            event.availabilities[pid].availability == undefined ||
            !availabilityTable[event.availabilities[pid].availability || ""])
    ) {
        updating["availabilities." + pid + ".dateFirstAvailable"] =
            dayjs().toISOString();
    }

    // if availability is negative, we remove timestamp
    if (
        !availabilityTable[availability]
    ) {
        updating["availabilities." + pid + ".dateFirstAvailable"] =
            dayjs().toISOString();
    }

    updating["nbopenspots"] = openspots.length;
    updating["openspots"] = openspots;

    return updating;
};

//
// UUID
//

export const firestoreAutoId = (): string => {
    const CHARS =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    let autoId = "";

    for (let i = 0; i < 20; i++) {
        autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
    }
    return autoId;
};


//
// Check alerts for an event for a user
//

export type AlertType = {
    text: string;
}
export type sAlertType = { [color: string]: AlertType[] }

export const computeAlerts = (league: LeagueType, event: EventType, uid: string) => {
    const res: sAlertType = {
        "red": [],
        "orange": [],
        "green": [],
        "blue": []
    }

    const playing = _.uniq(
        _.flatten(
            Object.values(event.matches).map((m) =>
                m.players.map((p) => p.PlayerID)
            )
        )
    );
    const confirmed = event.availabilities[uid]?.confirmed;
    const availability = event.availabilities[uid]?.availability;
    const knownavailability = availability && availability !== "unknown";
    const nbspots = event.nbopenspots;

    const scheduledNotConfirmed =
        playing.includes(uid) &&
        !confirmed &&
        availability &&
        availabilityTable[availability];
    const notPlayingUnknownAvailSpots =
        !playing.includes(uid) && !knownavailability && nbspots > 0;
    const ownEventOpenSpots = league.admins && league.admins.includes(uid) && nbspots > 0;

    if (!ownEventOpenSpots && notPlayingUnknownAvailSpots) {
        res["orange"].push({
            text: "There " + (nbspots === 1 ? "is " : "are ") + nbspots.toString()
                + " spot" + (nbspots === 1 ? "" : "s") + " open and your availability is unknown"
        } as AlertType)
    }

    if (ownEventOpenSpots) {
        res["red"].push({
            text: "There " + (nbspots === 1 ? "is " : "are ") + nbspots.toString()
                + " spot" + (nbspots === 1 ? "" : "s") + " open"
        } as AlertType)
    }

    if (scheduledNotConfirmed) {
        res["orange"].push({ text: "You are scheduled to play, but have not confirmed" } as AlertType)
    }

    const nomatch = league.admins && league.admins.includes(uid) && Object.keys(event.matches).length === 0

    if (nomatch) {
        res["blue"].push({ text: "Create a court and then spots" } as AlertType)
    }

    const nospot = league.admins && league.admins.includes(uid) &&
        Object.values(event.matches).reduce((p, m) => p && Object.values(m.spots).length === 0, true)

    if (!nomatch && nospot) {
        res["blue"].push({ text: "Create spots on your court by tapping on an empty space" } as AlertType)
    }

    const allSpotsConfirmed = Object.values(event.matches).reduce((p, m) => p && m.players.reduce((prev, p) => prev && (p.PlayerID !== undefined && event.availabilities[p.PlayerID]?.confirmed === true), true), true)

    if (league.admins && league.admins.includes(uid) && !nomatch && nbspots === 0 && !nospot && allSpotsConfirmed) {
        res["green"].push({ text: "All spots are confirmed!" } as AlertType)
    }

    const somePlayersNotConfirmed = !nomatch && !nospot && !allSpotsConfirmed
    if (!scheduledNotConfirmed && somePlayersNotConfirmed && nbspots === 0) {
        res["orange"].push({ text: "Some players haven't confirmed" } as AlertType)
    }

    //
    // Position adequacy
    //

    if (playing) {
        Object.entries(event.matches).reduce((p: boolean, [k, v]) => {
            const playerScheduled = v.players.filter(p => p.PlayerID === uid)
            if (playerScheduled.length === 1) {
                const nbspotsonside = Object.keys(v.spots).reduce((p, s) => p + (s[0] === playerScheduled[0].position[0] ? 1 : 0), 0)
                if (availability === "prefer doubles" && nbspotsonside === 1) {
                    res["blue"].push({ text: "You are scheduled to play singles but prefer doubles" } as AlertType)
                } else if (availability === "only doubles" && nbspotsonside === 1) {
                    res["red"].push({ text: "You are scheduled to play singles but want only doubles" } as AlertType)
                } else if (availability === "prefer singles" && nbspotsonside === 2) {
                    res["blue"].push({ text: "You are scheduled to play doubles but prefer singles" } as AlertType)
                } else if (availability === "only singles" && nbspotsonside === 2) {
                    res["red"].push({ text: "You are scheduled to play doubles but want only singles" } as AlertType)
                }
                return p && true
            } else { return p }
        }, true)
    }


    return res;
}


// if we don't have the tolist, we don't populate the isSingleRecipient node in a court

export const buildEventData = (league: LeagueType, event: EventType, toList?: string[]) => {
    const res: EventDataType = {
        description: event.description,
        location: event.location,
        has_address: event.address && event.address !== "" ? true : false,
        show_times: Object.values(event.matches).findIndex(
            (m) => m.offset && m.offset !== 0
        ) >= 0,
        when: {
            date_short: dayjs(event.when).tz(event.timezone || "America/New_York").format("YYYY-MM-DD"),
            date_long: dayjs(event.when).tz(event.timezone || "America/New_York").format("dddd, MMMM D, YYYY"),
            time: dayjs(event.when).tz(event.timezone || "America/New_York").format("h:mm A"),
            calendar: dayjs(event.when).tz(event.timezone || "America/New_York").calendar()
        },
        courts: Object.values(event.matches).sort((a, b) => a.order > b.order ? 1 : -1).map(m => {
            const spots: CourtSpotDataType[] = Object.values(m.spots).sort((sa, sb) => sa.position > sb.position ? 1 : -1).map(s => {
                const player = m.players.find(p => p.position === s.position)
                const cspot: CourtSpotDataType =
                {
                    side: s.position,
                    open: player ? false : true,
                    player: player && player.PlayerID ? {
                        first: player ? league.playerDetails[player.PlayerID]?.name || "???" : "",
                        fullname: player ? league.playerDetails[player.PlayerID]?.fullname || "???" : "",
                        availability: player ? event.availabilities[player.PlayerID]?.availability || "none" : "none",
                        available: player ? ((event.availabilities[player.PlayerID]?.availability || "none") != "none" ? true : false) : false,
                        isSingleRecipient: toList === undefined ? false : player && toList.length === 1 && player.PlayerID === toList[0],
                        playing: true,
                        confirmed: event.availabilities[player.PlayerID]?.confirmed ? true : false
                    } : player && player.postitname ? {
                        first: player.postitname,
                        fullname: player.postitname,
                        availability: "All",
                        available: true,
                        isSingleRecipient: false,
                        playing: true,
                        confirmed: true
                    } : undefined,
                    wins: (m.awins === "W" && s.position[0] === 'A') || (m.bwins === "W" && s.position[0] === 'B'),
                    loses: (m.awins === "L" && s.position[0] === 'A') || (m.bwins === "L" && s.position[0] === 'B'),
                    draws: m.awins === "D"
                }
                return cspot
            })
            const cdata: CourtDataType =
            {
                name: m.court,
                spots: spots,
                aspots: spots.filter((s) => s.side[0] === "A"),
                bspots: spots.filter((s) => s.side[0] === "B"),
                when: {
                    date_short: dayjs(event.when).add(m.offset || 0, "minute").tz(event.timezone || "America/New_York").format("YYYY-MM-DD"),
                    date_long: dayjs(event.when).add(m.offset || 0, "minute").tz(event.timezone || "America/New_York").format("dddd, MMMM D, YYYY"),
                    time: dayjs(event.when).add(m.offset || 0, "minute").tz(event.timezone || "America/New_York").format("h:mm A"),
                    calendar: dayjs(event.when).add(m.offset || 0, "minute").tz(event.timezone || "America/New_York").calendar()
                },
                score: m.score
            };
            return cdata;
        }),
        numberopenspots: event.nbopenspots
    }

    return res
}

const buildLeagueData = (league: LeagueType) => {
    const res: LeagueDataType = {
        name: league.name,
        admins: league.admins.map(l => league.playerDetails[l].fullname),
        eventtype: league.eventtype
    }
    return res;
}

export const processGroups = (league: LeagueType, event: EventType) => {
    const res: hEmailGroupsType = {};

    //
    // All
    //
    res["All"] = league.players

    //
    // Playing
    //
    res["Playing"] = Object.values(event.matches).reduce((p, c) => _.concat(p, c.players.filter(p => p.PlayerID !== undefined).map(player => player.PlayerID || "")), [] as string[])

    //
    // Not playing
    //
    res["Not playing"] = _.difference(res["All"], res["Playing"])

    //
    // Known availability
    //
    res["Known availability"] = Object.entries(event.availabilities).filter(([k, av]) => av.availability && av.availability != "unknown").map(([k, av]) => k)

    //
    // Unknown availability
    //
    res["Unknown availability"] = _.difference(res["All"], res["Known availability"])

    //
    // Available
    // 
    res["Available"] = Object.entries(event.availabilities).filter(([k, av]) => av.availability && ["All", "only singles", "prefer singles", "only doubles", "prefer doubles"].includes(av.availability)).map(([k, av]) => k)

    //
    // Also available
    // 
    res["Also available"] = _.difference(res["Available"], res["Playing"])

    //
    // Available and playing
    // 
    res["Available and playing"] = _.intersection(res["Available"], res["Playing"])

    //
    // Not available
    //
    res["Not available"] = Object.entries(event.availabilities).filter(([k, av]) => av.availability && av.availability == "none").map(([k, av]) => k)

    //
    // External
    //
    res["External"] = Object.entries(league.playerDetails).filter(([k, v]) => v.external === true).map(([k, v]) => k)

    //
    // Opted out
    //
    res["Opted out emails"] = Object.entries(league.playerDetails).filter(([k, v]) => v.optOutEmails === true).map(([k, v]) => k)

    //
    // Not External
    //
    res["Not external"] = _.difference(res["All"], res["External"])

    //
    // Confirmed
    //
    res["Confirmed"] = Object.entries(event.availabilities).filter(([k, av]) => av.confirmed && av.confirmed === true).map(([k, av]) => k)

    //
    // Not confirmed
    //
    res["Not confirmed"] = _.difference(res["All"], res["Confirmed"])

    //
    // League or event admins
    //
    res["Administrators"] = _.cloneDeep(league.admins);

    return res
}

export const availabilityTable: { [a: string]: boolean } = {
    "All": true,
    "none": false,
    "unknown": false,
    "prefer singles": true,
    "prefer doubles": true,
    "only singles": true,
    "only doubles": true,
}

export const availabilityLabels: { [a: string]: string } = {
    "All": "available",
    "none": "not available",
    "unknown": "unknown",
    "prefer singles": "available, prefer singles",
    "prefer doubles": "available, prefer doubles",
    "only singles": "available, only singles",
    "only doubles": "available, only doubles",
}

const buildGroupData = (league: LeagueType, event: EventType) => {
    const groups = processGroups(league, event);
    const res: { [g: string]: string[] } = {};

    Object.keys(groups).forEach((k) => {
        res[k] = groups[k].filter(pid => league.playerDetails[pid]?.fullname).map(pid => league.playerDetails[pid].fullname)
    })

    return res;
}

export const MergeEmailOptionsWithTemplate = (templateoptions: EmailTemplateOption[], emailoptions: EmailTemplateOption[]) => {

    const hTemplateOptions = _.keyBy(
        templateoptions,
        "name"
    )
    const hEmailOptions = _.keyBy(emailoptions, "name")

    const result = _.cloneDeep(hTemplateOptions);

    // applying all specified values
    Object.entries(hEmailOptions).forEach(([k, v]) => {
        if (result[k] && v.value !== undefined) {
            result[k].value = v.value
        }
    })

    return Object.entries(result).map(([name, val]) => {
        return { ...val, name: name };
    })
}

const server = user_emulator ? "http://localhost:5001/racquetbudz/us-central1/availability" : "https://us-central1-racquetbudz.cloudfunctions.net/availability"

const replaceablesData = {
    "###AVAILABILITYLINKS###": `
    <a href="${server}?id=###EMAILID###&cc=###CCID###&availability=All">I am available</a>
    <p/>
    <a href="${server}?id=###EMAILID###&cc=###CCID###&availability=none">I am NOT available</a>
    `,
    "###CONFIRMLINKS###": `
    <a href="${server}?id=###EMAILID###&cc=###CCID###&availability=All&confirm=yes">Confirmed, I can play</a>
    <p/>
    <a href="${server}?id=###EMAILID###&cc=###CCID###&availability=none&confirm=no">I am NO LONGER available to play</a>
    `,
    "###LOCATIONLINK###": `<a href=https://www.google.com/maps/place/###URLENCODEDADDRESS###" target="_blank" rel="noopener noreferrer">###LOCATION###</a>`
}

const replaceContent = (original: string, replaceables: { [k: string]: string }) => {

    var replaced = null;
    var next = original;

    while (next !== replaced) {
        replaced = next;
        next = Object.entries(replaceables).reduce((p, [k, v]) => p.split(k).join(v), replaced)
    }

    return replaced;
}

export const renderPartial = (partial: EmailPartialType, emailData: EmailDataType, p: PartialType) => {
    const ed = _.cloneDeep(emailData);

    partial.options.forEach((o) => {
        // we pre-process the option values
        if (o.type === "string" && o.value && o.value !== "") {
            try {
                const hb = Handlebars.compile(o.value, { noEscape: !p.isHtml })
                const processedValue = hb(ed);
                ed.options[o.name] = processedValue;
            } catch (e) {
                console.log("Handlebars exception: " + e)
                ed.options[o.name] = o.value;
            }
        } else {
            ed.options[o.name] = o.value;
        }
    });

    try {
        const hb = Handlebars.compile(p.contents, { noEscape: !p.isHtml })
        const processed = hb(ed)
        return replaceContent(processed, emailData.replaceables)
    } catch (e) {
        console.log("Handlebars exception: " + e)
        return "Handlebars exception"
    }
}

export const renderPartials = (partials: EmailPartialType[], emailData: EmailDataType, partialsDoc: PartialDocumentType, joinWith?: string) => {

    return partials.map((partial) => {
        const p = partialsDoc.partials.find((p) => p.PartialID === partial.PartialID)
        if (p) {
            return renderPartial(partial, emailData, p)
        } else return "";
    }).join(joinWith || "")
}

export const buildEmailDataPass1 = (league: LeagueType, event: EventType, toList?: string[]) => {
    const eventData = buildEventData(league, event, toList);
    const leagueData = buildLeagueData(league);
    const groups = buildGroupData(league, event);
    const groupids = processGroups(league, event)

    // const availabilitygroups: { group: string, isavailableandplaying: boolean, persons: string[] }[] = [];
    // availabilitygroups.push({ group: "Available and playing", isavailableandplaying: true, persons: groups["Available and playing"] })
    // availabilitygroups.push({ group: "Also available", isavailableandplaying: false, persons: groups["Also available"] })
    // availabilitygroups.push({ group: "Unavailable", isavailableandplaying: false, persons: groups["Unavailable"] })
    // availabilitygroups.push({ group: "Unknown availability", isavailableandplaying: false, persons: groups["Unknown availability"] })

    const emailData: EmailDataType = {
        event: eventData,
        options: {},
        league: leagueData,
        groups: _.zipObject(Object.keys(groups).map(k => k.replace(" ", "_")), Object.keys(groups).map(k => groups[k])),
        groupids: groupids,
        groups_counts: _.zipObject(Object.keys(groups).map(k => k.replace(" ", "_")), Object.keys(groups).map(k => groups[k].length)),
        replaceables: replaceablesData,
    }

    emailData.replaceables["###URLENCODEDADDRESS###"] = encodeURI(event.address || "")
    emailData.replaceables["###LOCATION###"] = event.location

    return (emailData)
}


//
// PASS 2: apply tolist to populate recipient node, recipients node, email node
//

export const buildEmailDataPass2 = (league: LeagueType, event: EventType, toList: string[], loggedPlayer: FirebaseUser, emailData: EmailDataType, email?: EmailType) => {
    // const eventData = buildEventData(league, event, toList);
    // const leagueData = buildLeagueData(league);
    // const groups = buildGroupData(league, event);
    // const groupids = processGroups(league, event)

    emailData.replaceables["###URLENCODEDADDRESS###"] = encodeURI(event.address || "")
    emailData.replaceables["###LOCATION###"] = event.location

    if (toList.length === 1) {
        const uid = toList[0]
        const available = emailData.groupids["Available"].includes(toList[0])
        // event.availabilities[toList[0]] ? availabilityTable[event.availabilities[toList[0]].availability as string] : false
        const playing = emailData.groupids["Playing"].includes(toList[0])
        const confirmed = emailData.groupids["Confirmed"].includes(toList[0])
        // Object.values(event.matches).reduce((p: boolean, m: MatchType) => Object.values(m.players).reduce((p, pl) => pl.PlayerID == toList[0], false), false)
        const playing_matches = Object.values(event.matches).filter(m => m.players.findIndex(p => p.PlayerID === uid) >= 0)
        const playing_time = dayjs.min(playing_matches.map(m => dayjs(event.when).add(m.offset || 0, "minute"))) || dayjs("1900-01-01")
        const playing_courts = playing_matches.filter(m => dayjs(event.when).add(m.offset || 0, "minute").isSame(playing_time)).map(m => m.court)
        const playing_court = playing_courts.length >= 0 ? playing_courts[0] : ""

        emailData.recipient = {
            first: league.playerDetails[toList[0]].name,
            fullname: league.playerDetails[toList[0]].fullname,
            availability: !available ? "not available" : availabilityLabels[event.availabilities[toList[0]].availability as string] || "unknown",
            available: available,
            playing: playing,
            confirmed: confirmed,
            playing_court: playing_court,
            playing_time: playing_time.tz(event.timezone).format("LT")
        }
    }

    emailData.recipients = toList.map(tl => {
        const available = emailData.groupids["Available"].includes(tl)
        // event.availabilities[toList[0]] ? availabilityTable[event.availabilities[toList[0]].availability as string] : false
        const playing = emailData.groupids["Playing"].includes(tl)
        const confirmed = emailData.groupids["Confirmed"].includes(toList[0])
        // const available = event.availabilities[tl] ? availabilityTable[event.availabilities[tl].availability as string] : false
        // const playing = Object.values(event.matches).reduce((p: boolean, m: MatchType) => Object.values(m.players).reduce((p, pl) => pl.PlayerID == tl, false), false)

        return {
            first: league.playerDetails[tl].name,
            fullname: league.playerDetails[tl].fullname,
            availability: !available ? "not available" : availabilityLabels[event.availabilities[tl].availability as string] || "unknown",
            available: available,
            playing: playing,
            confirmed: confirmed
        }
    })

    if (email) emailData.email = _.cloneDeep(email);

    // console.log(emailData)

    return emailData;
}


//
// returns the subject and contents to use for processing
//

export type SubjectContentsOptionsType = {
    subject: string;
    contents: string;
    options: EmailTemplateOption[];
}

export const getSubjectContentsOptions = (email: EmailType, templates: hTemplateType, partials: PartialDocumentType) => {
    if (email.TemplateID) {
        const res: SubjectContentsOptionsType =
        {
            subject: templates[email.TemplateID].subject || "",
            contents: templates[email.TemplateID].contents || "",
            options: email.options
        }
        return res;
    } else if (email.subjectPartials && email.contentPartials) {
        const res: SubjectContentsOptionsType = {
            subject: email.subjectPartials.map((p) => partials.partials.find((pa) => pa.PartialID === p.PartialID)).map((p) => p?.contents || "<p>Partial not found</p>").join(""),
            contents: email.contentPartials.map((p) => partials.partials.find((pa) => pa.PartialID === p.PartialID)).map((p) => p?.contents || "<p>Partial not found</p>").join(""),
            options: _.flatten(email.subjectPartials.map((p) => p.options).concat(email.contentPartials.map((p) => p.options)))
        };
        return res
    } else {
        const res: SubjectContentsOptionsType = {
            subject: "No template or partials",
            contents: "No template or partials",
            options: []
        };
        return res;
    }
}

export const processTemplateFromPartials = (league: LeagueType,
    event: EventType,
    groups: hEmailGroupsType,
    toList: string[],
    loggedPlayer: FirebaseUser,
    email: EmailType,
    partialDocument: PartialDocumentType,
    ccid?: string) => {
    try {
        // build data
        var emailData = buildEmailDataPass1(league, event, toList)
        emailData = buildEmailDataPass2(league, event, toList, loggedPlayer, emailData, email)

        if (ccid) {
            emailData.replaceables["###CCID###"] = ccid;
        }
        else {
            // if a tolist has only one recipient, we can add links
            emailData.replaceables["###CCID###"] = "0";
        }
        emailData.replaceables["###EMAILID###"] = email.EmailID;

        // now processing subject and contents

        const subject = renderPartials(email.subjectPartials || [], emailData, partialDocument)
        const contents = renderPartials(email.contentPartials || [], emailData, partialDocument, "<p/>")

        return (
            {
                subject: subject,
                contents: contents
            }
        )
    } catch (e) {
        console.log(e)
        return (
            { subject: "", contents: "Error processing template" }
        )
    }
}

export const processTemplate2 = (league: LeagueType, event: EventType, subject: string, contents: string, groups: hEmailGroupsType, toList: string[], loggedPlayer: FirebaseUser, options: EmailTemplateOption[], email: EmailType, ccid?: string) => {
    try {
        // build data
        var emailData = buildEmailDataPass1(league, event, toList)
        emailData = buildEmailDataPass2(league, event, toList, loggedPlayer, emailData, email)

        // if (email) emailData.email = _.cloneDeep(email);

        if (ccid) {
            emailData.replaceables["###CCID###"] = ccid;
        }
        else {
            // if a tolist has only one recipient, we can add links
            emailData.replaceables["###CCID###"] = "0";
        }
        emailData.replaceables["###EMAILID###"] = email.EmailID;
        if (event.address && event.address !== "") {
            emailData.replaceables["###URLENCODEDADDRESS###"] = encodeURI(event.address)
            emailData.replaceables["###LOCATION###"] = event.location
        }

        // do replacement first, before template processing
        const rsubject = Object.entries(emailData.replaceables).reduce((p, [k, v]) => p.split(k).join(v), subject)
        const rcontents = Object.entries(emailData.replaceables).reduce((p, [k, v]) => p.split(k).join(v), contents)

        // console.log(rcontents)
        // console.log(JSON.stringify(emailData, null, 2))

        // compile the templates
        const subjectTemplate = Handlebars.compile(rsubject, { noEscape: true })
        const contentsTemplate = Handlebars.compile(rcontents)

        const hOptions: { [k: string]: string | boolean | undefined } = {}
        options.forEach(o => {
            // we also replace the replaceables in the option values
            if (typeof o.value === "string") {
                if (o.value.includes("{{")) {
                    const c = Handlebars.compile(o.value)
                    hOptions[o.name] = c(emailData)
                } else {
                    hOptions[o.name] = o.value
                }
            } else {
                hOptions[o.name] = o.value
            }
        })

        // we add the options to our data
        emailData.options = hOptions;

        // console.log("Options")
        // console.log(JSON.stringify(hOptions, null, 2))
        // console.log(emailData)

        // console.log(emailData, null, 2)

        return (
            {
                subject: subjectTemplate(emailData),
                contents: contentsTemplate(emailData)
            }
        )
    } catch (e) {
        console.log(e)
        return (
            { subject: "", contents: "Error processing template" }
        )
    }
}

export const processToList = (email: EmailType, groups: hEmailGroupsType) => {
    var toList: string[] = []

    //
    // add groups
    //
    email.toGroups.forEach((tg) => {
        toList = _.union(toList, groups[tg])
    })

    //
    // less groups
    //
    email.lessGroups.forEach((lg) => {
        toList = _.difference(toList, groups[lg])
    })

    //
    // add individuals
    //
    toList = _.union(toList, email.toIndividuals)

    //
    // less individuals
    //
    toList = _.difference(toList, email.lessIndividuals)

    return toList
}

//
// returns a dayjs
//

export const computeTimeToSend = (event: EventType, email: EmailType) => {
    if (email.whenCategory === "immediately") {
        return dayjs().add(-1, "minute").toJSON() // to make sure it gets sent now
    } else if (email.whenCategory === "before") {
        return dayjs(event.when).add(-email.whenValue, "minutes").toJSON()
    } else {
        return dayjs("2100-01-01").toJSON()
    }
}