import "../App.css";

// REACT + GENERAL LIBRARIES

import React, {
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useRef,
} from "react";
import dayjs, { Dayjs } from "dayjs";

// LODASH

import * as _ from "lodash";

// CONTEXTS

import { createContext, useContext } from "react";

// FIREBASE

import {
  collection,
  getDocs,
  query,
  onSnapshot,
  doc,
  updateDoc,
  deleteField,
  addDoc,
  setDoc,
  deleteDoc,
  getDoc,
  where,
  arrayUnion,
  arrayRemove,
  FieldValue,
} from "firebase/firestore";
import { db } from "../firebase";

// MUI

import {
  AppBar,
  Toolbar,
  IconButton,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  stepContentClasses,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  Switch,
  Icon,
  Menu,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  FormGroup,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//
// TYPES
//

import { Event } from "./Event";
import { EventSummary, EventType, Invitation, PlayerType } from "../types";
import { PlayerDetailsType, LeagueType } from "../types";

// MUI ICONS

import HomeIcon from "@mui/icons-material/Home";
import TodayIcon from "@mui/icons-material/Today";
import FilterListIcon from "@mui/icons-material/FilterList";
import BuildIcon from "@mui/icons-material/Build";
import CheckIcon from "@mui/icons-material/Check";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  AppContext,
  updateLeague,
  updateLeagueWithEventSummary,
  updatePlayerRecord,
} from "../App";

import { style } from "./Event";
import { eventCourtTimes, firestoreAutoId } from "../processEmails";
import { EventForm } from "./EventForm";
import { LeaguePlayerForm } from "./LeaguePlayerForm";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Label } from "@mui/icons-material";
import { select } from "firebase-functions/params";

type ParticipantInvitationProps = {
  invitation: Invitation;
};

const ParticipantInvitation = ({ invitation }: ParticipantInvitationProps) => {
  const { state, setState } = useContext(AppContext);

  const rescind = () => {
    if (invitation.toPlayerID) {
      const pref = doc(db, "players", invitation.toPlayerID);
      updateDoc(pref, "invitations." + invitation.InvitationID, deleteField());
    } else if (invitation.toEmail) {
      const pref = doc(db, "invitations", invitation.InvitationID);
      getDoc(pref).then((doc) => {
        if (doc.exists()) {
          deleteDoc(pref);
        }
      });
    }
    const ref = doc(db, "leagues", invitation.LeagueID);
    updateDoc(ref, "invitations." + invitation.InvitationID, deleteField());
  };

  return (
    <Paper sx={{ padding: 1, width: "100%" }}>
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Stack direction="column">
          <Typography sx={{ fontSize: "12px" }}>
            {dayjs(invitation.date).format("LLL")}
            <br />
            {invitation.toEmail}
          </Typography>
          {invitation.status === "declined" ? (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              declined
            </Typography>
          ) : invitation.status === "accepted" ? (
            <Typography sx={{ fontSize: "12px", color: "green" }}>
              accepted
            </Typography>
          ) : (
            <></>
          )}
        </Stack>
        <IconButton onClick={rescind}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
};

type AddParticipantProps = {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  lid: string;
  event: EventType;
};

export const AddParticipant = ({
  showModal,
  setShowModal,
  lid,
  event,
}: AddParticipantProps) => {
  const { state, setState } = useContext(AppContext);

  // collecting all players from all leagues
  const [players, setPlayers] = useState<
    (PlayerDetailsType & { PlayerID: string })[]
  >([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<string[]>([]);
  const [panel, setPanel] = useState("in leagues");

  const [searchResults, setSearchResults] = useState<PlayerType[] | undefined>(
    undefined
  );

  const league = state.leagues[lid];

  const addPlayers = () => {
    const changes: { [a: string]: any } = {};
    changes["players"] = _.union(league.players, selected);
    changes["playerDetails"] = _.keyBy(
      _.unionBy(
        Object.entries(league.playerDetails).map(([k, v]) => {
          return { ...v, PlayerID: k };
        }),
        players.filter((p) => selected.includes(p.PlayerID)),

        (p) => p.PlayerID
      ),
      (p) => p.PlayerID
    );
    updateLeague(league, changes);
    setShowModal(false);
  };

  // invite the first matching player to join this league
  const invite = () => {
    if (state.loggedPlayer) {
      const newInvite: Invitation = {
        InvitationID: firestoreAutoId(),
        toEmail: search,
        date: dayjs().toISOString(),
        LeagueID: league.LeagueID,
        leagueName: league.name,
        fromName: state.loggedPlayer.displayName || "",
        fromPlayerID: state.loggedPlayer.uid,
        status: "new",
      };

      if (searchResults && searchResults.length > 0) {
        const playerToInvite = searchResults[0];
        newInvite.toPlayerID = playerToInvite.PlayerID;

        // checking if we have already invited them
        if (league.players.includes(playerToInvite.PlayerID)) {
          alert(playerToInvite.fullname + " is already in this league");
        } else if (
          league.invitations &&
          Object.values(league.invitations).findIndex(
            (i) => i.toEmail === search
          ) >= 0
        ) {
          alert(
            playerToInvite.fullname + " was already invited to this league"
          );
        } else {
          // adding this invite to player and to league
          const pref = doc(db, "players", playerToInvite.PlayerID);
          updateDoc(pref, "invitations." + newInvite.InvitationID, newInvite);
          const ref = doc(db, "leagues", league.LeagueID);
          updateDoc(ref, "invitations." + newInvite.InvitationID, newInvite);
          alert("Invited " + playerToInvite.fullname);
        }
      } else {
        if (
          league.invitations &&
          Object.values(league.invitations).findIndex(
            (i) => i.toEmail === search
          ) >= 0
        ) {
          alert(search + " was already invited to this league");
        } else {
          const invitationRef = doc(db, "invitations", newInvite.InvitationID);
          const ref = doc(db, "leagues", league.LeagueID);
          updateDoc(ref, "invitations." + newInvite.InvitationID, newInvite);
          setDoc(invitationRef, newInvite);
          alert("Invited " + newInvite.toEmail);
        }
      }
    }
  };

  // searches players by email
  const searchEmail = () => {
    if (
      ["sysadmin", "professional"].includes(
        state.tokenResult?.claims.license as string
      )
    ) {
      const q = query(collection(db, "players"), where("email", "==", search));
      getDocs(q).then((docs) => {
        setSearchResults(docs.docs.map((d) => d.data() as PlayerType));
      });
    }
  };

  const addNow = () => {
    if (searchResults && searchResults.length > 0) {
      const playerToInvite = searchResults[0];

      // checking if we have already invited them
      if (league.players.includes(playerToInvite.PlayerID)) {
        alert(playerToInvite.fullname + " is already in this league");
      } else {
        const index = league.invitations
          ? Object.values(league.invitations).findIndex(
              (i) => i.toEmail === search
            )
          : -1;
        if (league.invitations && index >= 0) {
          alert("removing invitations");
          const pref = doc(db, "players", playerToInvite.PlayerID);
          const invitation = _.cloneDeep(league.invitations[index]);
          updateDoc(
            pref,
            "invitations." + invitation.InvitationID,
            deleteField
          );
          const ref = doc(db, "leagues", league.LeagueID);
          updateDoc(ref, "invitations." + invitation.InvitationID, deleteField);
        }

        const ref = doc(db, "leagues", league.LeagueID);
        const playerDetail: PlayerDetailsType = {
          fullname: playerToInvite.fullname,
          email: playerToInvite.email,
          name: playerToInvite.fullname,
          external: false,
          contractshares: 0,
        };
        updateDoc(
          ref,
          "players",
          arrayUnion(playerToInvite.PlayerID),
          "playerDetails." + playerToInvite.PlayerID,
          playerDetail
        );
      }
    }
  };

  //
  // collect all players
  //

  useEffect(() => {
    //
    // we build a hash of all players seen through events or leagues
    // we have to retain the current league players in priority
    // so that if the user adds / remove players from the current
    // playerdetails for the league, they do not lose details such
    // as league name or contractshares
    //

    if (state) {
      var players: {
        [PlayerID: string]: PlayerDetailsType;
      } = {};
      Object.values(state.leagues).forEach((l) =>
        Object.keys(l.playerDetails).forEach((pid) => {
          if (!players[pid] || l.LeagueID === lid)
            players[pid] = {
              ...l.playerDetails[pid],
              external: false,
              contractshares: 0,
            };
        })
      );
      setPlayers(
        Object.keys(players)
          .sort((ka, kb) =>
            players[ka].fullname > players[kb].fullname ? 1 : -1
          )
          .filter((ka) => !league.players.includes(ka))
          .map((k) => {
            return { ...players[k], PlayerID: k };
          })
      );
    }
  }, [state.leagues]);

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Accordion
          expanded={panel === "in leagues"}
          onChange={() => setPanel("in leagues")}
        >
          <AccordionSummary>Players in your leagues / events</AccordionSummary>
          <AccordionDetails>
            <Stack>
              <TextField
                label="search by name or email"
                value={search}
                onChange={(e) => {
                  setSearch(e.currentTarget.value);
                }}
              />
              <List
                sx={{
                  border: 1,
                  minHeight: "300px",
                  maxHeight: "300px",
                  overflow: "auto",
                  marginBottom: 1,
                  marginTop: 1,
                  borderColor: "#1876D2",
                }}
              >
                {players
                  .filter(
                    (p) =>
                      search === "" ||
                      p.fullname.toLowerCase().includes(search) ||
                      p.email.toLowerCase().includes(search)
                  )
                  .map((p) => (
                    <ListItem sx={{ padding: 0, margin: 0, paddingLeft: 1.5 }}>
                      <FormControlLabel
                        label={
                          <Typography sx={{ lineHeight: 1 }}>
                            {p.fullname}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            sx={{ padding: 0, margin: 0.5 }}
                            checked={selected.includes(p.PlayerID)}
                            onChange={(e) => {
                              if (selected.includes(p.PlayerID)) {
                                setSelected(
                                  selected.filter((pid) => pid != p.PlayerID)
                                );
                              } else {
                                setSelected(selected.concat([p.PlayerID]));
                              }
                            }}
                          />
                        }
                      />
                    </ListItem>
                  ))}
              </List>
              <Stack
                direction="row"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    addPlayers();
                  }}
                >
                  Add{" "}
                  {selected.length > 0
                    ? selected.length.toString() +
                      " player" +
                      (selected.length > 1 ? "s" : "")
                    : ""}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel === "invite"}
          onChange={() => {
            setPanel("invite");
          }}
        >
          <AccordionSummary>Search by email</AccordionSummary>
          <AccordionDetails>
            <Stack direction="column" spacing={1}>
              <TextField
                size="small"
                label="email"
                type="email"
                value={search}
                onChange={(e) => {
                  setSearch(e.currentTarget.value);
                  setSearchResults(undefined);
                }}
              />
              <Button
                variant="contained"
                onClick={() => {
                  searchEmail();
                }}
              >
                Lookup
              </Button>
              <Box sx={{ minHeight: 100 }}>
                {searchResults === undefined ? (
                  <></>
                ) : searchResults.length === 0 ? (
                  <Stack direction="column">
                    <Typography>Not an active user yet</Typography>
                    <Button variant="outlined" onClick={invite}>
                      Send an email
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction="column">
                    {searchResults.slice(0, 1).map((p) => (
                      <Stack direction="column">
                        <Typography>Found {p.fullname}</Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            variant="outlined"
                            onClick={() => {
                              invite();
                            }}
                          >
                            Invite
                          </Button>
                          {state.tokenResult?.claims.license === "sysadmin" ? (
                            <Button
                              variant="outlined"
                              onClick={() => {
                                addNow();
                              }}
                            >
                              Add to league
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Box>
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={panel === "invited"}
          onChange={() => {
            setPanel("invited");
          }}
        >
          <AccordionSummary>
            Invitations
            {league.invitations && Object.keys(league.invitations).length > 0
              ? " (" + Object.keys(league.invitations).length.toString() + ")"
              : ""}
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <List
                sx={{
                  maxHeight: 400,
                  overflow: "auto",
                  width: "100%",
                  // border: 1,
                  // padding: 1,
                }}
              >
                {Object.values(league.invitations || []).map((i) => {
                  return (
                    <ListItem sx={{ display: "flex", margin: 0, padding: 0 }}>
                      <ParticipantInvitation invitation={i} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Modal>
  );
};
